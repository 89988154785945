import React from "react";
import { useWeb3React } from "@web3-react/core";

import HeaderLogo from "../../assets/image/small_logo.png";
import useAuth from "../../hooks/useAuth";
import "./style.scss";
import { setupNetwork, switchNetwork } from "../../wallet/ethereum";
import { useEffect } from "react";

export default function Header() {
  const { login, logout } = useAuth();
  const { account, active, chainId } = useWeb3React();

  const connectButtonClicked = async () => {
    if (active) {
      if (chainId !== Number(process.env.REACT_APP_CHAIN_ID)) {
        await setupNetwork();
        // await switchNetwork(process.env.REACT_APP_CHAIN_ID_HEX);
      } else {
        logout();
      }
    } else {
      login();
    }
  };

  const accountEllipsis = account
    ? `${account.substring(0, 5)}...${account.substring(account.length - 4)}`
    : null;

  return (
    <header className="header">
      <div className="header-left">
        <div className="header-logo">
          <img src={HeaderLogo} alt="header-logo" />
        </div>
      </div>
      <ul className="header-menu">
        <li className="header-menu-item">
          <a
            className="header-menu-link"
            href="#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mining
          </a>
        </li>
        <li className="header-menu-item">
          <a
            className="header-menu-link"
            href="#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Referral
          </a>
        </li>
        <li className="header-menu-item">
          <a
            className="header-menu-link"
            href="#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Docs
          </a>
        </li>
        <li className="header-menu-item">
          <a
            className="header-menu-link"
            href="#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contract
          </a>
        </li>
      </ul>
      <button onClick={connectButtonClicked} className="btn btn-primary">
        {(function () {
          if (active) {
            return chainId !== Number(process.env.REACT_APP_CHAIN_ID)
              ? "Switch network"
              : accountEllipsis;
          } else {
            return "Connect Wallet";
          }
        })()}
      </button>
    </header>
  );
}
