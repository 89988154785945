import React from "react";
import "./style.scss";

export default function Miner() {
  return (
    <section className="miner-wrapper" data-aos="fade-up">
      {/* <div className="miner">
        <div className="miner-info">
          <h4 className="sub-title text-orange">MINER INFORMATION</h4>
          <div>
            <p>
              BSC Miner is designed for individuals with a long-term vision. It
              is not for those who want instant profits that ultimately harm
              others. Miners can be viewed as internal tokens whose value rises
              and falls based on the combined actions of the community, just
              like any other coin or token that you may hold. But, unlike your
              average coins and tokens, BSC Miner allows you to stabilize and
              increase your TVL by taking long-term advantage of its compound
              feature.
            </p>
            <p>
              TVL is your current estimated Total Value Locked, an estimated
              value of your total miners. Once miners are hired, they work for
              you indefinitely, therefore your TVL can not be withdrawn in one
              lump sum. When you hire miners, they fill your barrel with BNB
              throughout the day with an estimated daily total of 4% of your
              TVL. You can pocket or compound the BNB accumulated in your barrel
              at any time. Pocketing too often will ensure a decrease in TVL,
              which in turn will ensure a decrease in your daily payouts. The
              value of miners continuously increases and decreases throughout
              the day, therefore it is normal to see your personal TVL
              fluctuating as your barrel continues to fill.
            </p>
          </div>
        </div>
        <ul className="miner-list">
          <li className="miner-item">
            <h4 className="sub-title text-orange">SUSTAINABILITY</h4>
            <div>
              <p>
                BSC MINER is sustained by continued community support, just like
                every other crypto coin, token or project. The difference is,
                BNB Miner also has an algorithm that doesn't allow others to
                instantly dump their coins on the community.
              </p>
              <p>
                BSC Miner also allows you to stabilize and increase your TVL by
                taking long-term advantage of its compound feature.
              </p>
            </div>
          </li>
          <li className="miner-item">
            <h4 className="sub-title text-orange">VERIFIED CONTRACT</h4>
            <div>
              <p>
                The BSC Miner contract is public, verified and can be viewed{" "}
                <a
                  className="text-orange-dark text-link"
                  href="https://bscscan.com/address/0x7c35458f018e7A43D40EDb63916198EA2293021c"
                  target="_blank"
                >
                  Here
                </a>{" "}
                on BSCScan.
              </p>
              <p>
                Contract is improved from Baked Bean V1. No Risk function. No
                proxy contract. View AUDIT
                <a className="text-orange-dark text-link" href="#">
                  Here
                </a>
              </p>
            </div>
          </li>
        </ul>
      </div> */}
      <div className="miner-info-important">
        <span className="text-orange">IMPORTANT INFORMATION:</span> Staking fee
        is a flat 5%. Use the Earnings Calculator to determine how much a stake
        will earn daily!
      </div>
    </section>
  );
}
