import Header from "./components/Header";

import "./common.scss";
import Intro from "./components/Intro";
import Pool from "./components/Pool";
import Referral from "./components/Referral";
import Miner from "./components/Miner";
import Footer from "./components/Footer";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);
  return (
    <div className="app" lang="en-US">
      <div className="container">
        <Header />
        <Intro />
        <Pool />
        <Referral />
        <Miner />
      </div>
      <Footer />
    </div>
  );
}

export default App;
