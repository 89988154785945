import React from "react";
import IntroMainImg from "../../assets/image/intro.png";
import IntroTitle from "../../assets/image/intro-title.png";
import "./style.scss";

export default function Intro() {
  return (
    <section className="intro">
      <div className="intro-left" data-aos="fade-right">
        <div className="intro-left-content">
          <div className="intro-left-content-main">
            <div className="intro-left-img">
              <img src={IntroTitle} alt="bsc-img" />
            </div>
          </div>
          <p>
            Explore the unique realm of SolMiner, an innovative DeFi venture
            thriving on Solana, where daily earnings can soar up to 10%.
          </p>
        </div>
        <div className="intro-left-btn-list">
          <a
            href="https://twitter.com/SolMinerX"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-small btn-primary"
          >
            Telegram
          </a>
          <a
            href="https://twitter.com/SolMinerX"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-small btn-primary"
          >
            Twitter
          </a>
        </div>
      </div>
      <div className="intro-right" data-aos="fade-left">
        <div className="intro-right-wrapper">
          <img className="intro-img-bg" src={IntroMainImg} alt="intro-img" />
          {/* <div className="intro-img-main">
            <img src={IntroMainImg} alt="intro-img" />
          </div> */}
        </div>
      </div>
    </section>
  );
}
