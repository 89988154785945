import React, { useState } from "react";
import PoolRightImg from "../../assets/image/bg-pool.png";
import logoText from "../../assets/image/intro-title.png";

import MinusBtn from "../../assets/image/minus-visible.png";
import PlusBtn from "../../assets/image/plus-visible.png";
import sol from "../../assets/image/sol.png";
import "./style.scss";

const D = 0.01;

export default function Pool() {
  const [poolInputValue, setPoolInputValue] = useState(0.01);
  const [isMinusActive, setIsMinusActive] = useState(false);
  return (
    <section className="pool" data-aos="fade-up">
      <h4 className="sub-title text-orange">THE VALUE OF MINERS</h4>
      <div className="pool-total">
        <span className="text-orange">TOTAL POOL:</span>
        <div className="pool-total-right">
          <span>1803984</span>
          <div className="pool-total-right-img">
            <img src={sol} alt="" />
          </div>
        </div>
      </div>
      <div className="pool-wrapper">
        <div className="pool-ab">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="135"
            viewBox="0 0 216 215"
            fill="none"
          >
            <g clip-path="url(#clip0_1_102)">
              <path
                d="M167.497 62.3316L22.3141 213.379C20.9395 214.81 18.8711 215.353 16.9663 214.771L11.2782 213.039C9.92321 212.627 8.79081 211.692 8.13624 210.437L0.602196 196.075C-0.504019 193.963 -0.0589148 191.381 1.68877 189.76L154.837 47.9891C156.651 46.3091 159.504 46.5183 161.056 48.4402L167.732 56.7031C169.081 58.3701 168.976 60.7823 167.49 62.3316H167.497Z"
                fill="url(#paint0_linear_1_102)"
              />
              <path
                d="M0.602196 196.088C-0.504019 193.977 -0.0589148 191.394 1.68877 189.773L154.837 47.9891C156.507 46.4463 159.046 46.5183 160.65 48.0283L1.51204 197.82L0.602196 196.088Z"
                fill="url(#paint1_linear_1_102)"
              />
              <path
                d="M167.739 56.7031C169.087 58.3701 168.983 60.7823 167.497 62.3316L22.3141 213.379C20.9396 214.81 18.8711 215.353 16.9663 214.771L11.2782 213.039C10.6825 212.856 10.1392 212.562 9.65485 212.196L167.359 56.2324L167.739 56.7031Z"
                fill="url(#paint2_linear_1_102)"
              />
              <path
                d="M166.698 74.3142C166.698 74.3142 224.385 133.305 214.959 189.584C214.959 189.584 203.949 147.635 148.619 92.3698L166.698 74.3142Z"
                fill="url(#paint3_linear_1_102)"
              />
              <path
                d="M148.606 92.3698C203.085 146.778 214.593 188.276 214.94 189.564L214.953 189.59C204.859 126.389 163.897 90.2256 163.897 90.2256C162.182 86.9962 160.565 84.2702 159.02 81.9822L148.606 92.3698Z"
                fill="url(#paint4_linear_1_102)"
              />
              <path
                d="M141.589 49.2377C141.589 49.2377 82.521 -8.37411 26.163 1.03937C26.163 1.03937 68.1664 12.0348 123.503 67.2933L141.582 49.2377H141.589Z"
                fill="url(#paint5_linear_1_102)"
              />
              <path
                d="M123.51 67.2998C69.0305 12.8912 27.4787 1.39891 26.1892 1.05244L26.163 1.03937C89.4464 11.1196 125.657 52.0291 125.657 52.0291C128.89 53.7418 131.62 55.3565 133.911 56.8992L123.51 67.2998Z"
                fill="url(#paint6_linear_1_102)"
              />
              <path
                d="M135.698 65.7113C141.222 69.8101 146.603 74.1442 151.912 78.5437L128.884 102.502C125.893 95.3638 121.71 88.9443 115.701 84.2245L135.704 65.7048L135.698 65.7113Z"
                fill="url(#paint7_linear_1_102)"
              />
              <path
                d="M131.731 44.7206L117.979 61.2595C115.884 63.7829 115.616 66.9861 117.37 68.7315L147.179 98.5016C148.927 100.247 152.141 99.9855 154.661 97.8937L171.221 84.1591C172.923 82.7471 174.016 80.7337 174.108 78.8248L174.278 75.4647C174.343 74.1573 173.938 73.0002 173.133 72.1962L143.716 42.8183C142.911 42.0142 141.753 41.6089 140.444 41.6743L137.079 41.8442C135.168 41.9423 133.152 43.0274 131.738 44.7271L131.731 44.7206Z"
                fill="url(#paint8_linear_1_102)"
              />
              <path
                d="M117.979 61.2595L124.289 53.6765L162.142 91.6899L154.667 97.8937C152.141 99.9855 148.933 100.254 147.186 98.5016L117.377 68.7315C115.629 66.9861 115.891 63.7763 117.985 61.253L117.979 61.2595Z"
                fill="url(#paint9_linear_1_102)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_1_102"
                x1="108.573"
                y1="190.035"
                x2="51.8789"
                y2="41.2198"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B27E10" />
                <stop offset="0.09" stop-color="#E2C05B" />
                <stop offset="0.14" stop-color="#F6DC7B" />
                <stop offset="0.26" stop-color="#FFF0A3" />
                <stop offset="0.36" stop-color="#F6DC7B" />
                <stop offset="0.49" stop-color="#B27E10" />
                <stop offset="0.57" stop-color="#E2C05B" />
                <stop offset="0.61" stop-color="#F6DC7B" />
                <stop offset="0.72" stop-color="#FFF0A3" />
                <stop offset="0.84" stop-color="#F6DC7B" />
                <stop offset="1" stop-color="#B27E10" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1_102"
                x1="98.1979"
                y1="168.103"
                x2="53.266"
                y2="50.1728"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B27E10" />
                <stop offset="0.09" stop-color="#E2C05B" />
                <stop offset="0.14" stop-color="#F6DC7B" />
                <stop offset="0.26" stop-color="#FFF0A3" />
                <stop offset="0.36" stop-color="#F6DC7B" />
                <stop offset="0.49" stop-color="#B27E10" />
                <stop offset="0.57" stop-color="#E2C05B" />
                <stop offset="0.61" stop-color="#F6DC7B" />
                <stop offset="0.72" stop-color="#FFF0A3" />
                <stop offset="0.84" stop-color="#F6DC7B" />
                <stop offset="1" stop-color="#B27E10" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1_102"
                x1="95.7891"
                y1="151.171"
                x2="34.1349"
                y2="3.4533"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B27E10" />
                <stop offset="0.09" stop-color="#E2C05B" />
                <stop offset="0.14" stop-color="#F6DC7B" />
                <stop offset="0.26" stop-color="#FFF0A3" />
                <stop offset="0.36" stop-color="#F6DC7B" />
                <stop offset="0.49" stop-color="#B27E10" />
                <stop offset="0.57" stop-color="#E2C05B" />
                <stop offset="0.61" stop-color="#F6DC7B" />
                <stop offset="0.72" stop-color="#FFF0A3" />
                <stop offset="0.84" stop-color="#F6DC7B" />
                <stop offset="1" stop-color="#B27E10" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_1_102"
                x1="266.526"
                y1="56.3501"
                x2="203.155"
                y2="119.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B27E10" />
                <stop offset="0.09" stop-color="#E2C05B" />
                <stop offset="0.14" stop-color="#F6DC7B" />
                <stop offset="0.26" stop-color="#FFF0A3" />
                <stop offset="0.36" stop-color="#F6DC7B" />
                <stop offset="0.49" stop-color="#B27E10" />
                <stop offset="0.57" stop-color="#E2C05B" />
                <stop offset="0.61" stop-color="#F6DC7B" />
                <stop offset="0.72" stop-color="#FFF0A3" />
                <stop offset="0.84" stop-color="#F6DC7B" />
                <stop offset="1" stop-color="#B27E10" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_1_102"
                x1="201.024"
                y1="92.3436"
                x2="139.097"
                y2="249.29"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B27E10" />
                <stop offset="0.09" stop-color="#E2C05B" />
                <stop offset="0.14" stop-color="#F6DC7B" />
                <stop offset="0.26" stop-color="#FFF0A3" />
                <stop offset="0.36" stop-color="#F6DC7B" />
                <stop offset="0.49" stop-color="#B27E10" />
                <stop offset="0.57" stop-color="#E2C05B" />
                <stop offset="0.61" stop-color="#F6DC7B" />
                <stop offset="0.72" stop-color="#FFF0A3" />
                <stop offset="0.84" stop-color="#F6DC7B" />
                <stop offset="1" stop-color="#B27E10" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_1_102"
                x1="67.4791"
                y1="87.6957"
                x2="78.8017"
                y2="44.3995"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B27E10" />
                <stop offset="0.09" stop-color="#E2C05B" />
                <stop offset="0.14" stop-color="#F6DC7B" />
                <stop offset="0.26" stop-color="#FFF0A3" />
                <stop offset="0.36" stop-color="#F6DC7B" />
                <stop offset="0.49" stop-color="#B27E10" />
                <stop offset="0.57" stop-color="#E2C05B" />
                <stop offset="0.61" stop-color="#F6DC7B" />
                <stop offset="0.72" stop-color="#FFF0A3" />
                <stop offset="0.84" stop-color="#F6DC7B" />
                <stop offset="1" stop-color="#B27E10" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_1_102"
                x1="174.154"
                y1="-2.81754"
                x2="-83.7154"
                y2="93.472"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B27E10" />
                <stop offset="0.09" stop-color="#E2C05B" />
                <stop offset="0.14" stop-color="#F6DC7B" />
                <stop offset="0.26" stop-color="#FFF0A3" />
                <stop offset="0.36" stop-color="#F6DC7B" />
                <stop offset="0.49" stop-color="#B27E10" />
                <stop offset="0.57" stop-color="#E2C05B" />
                <stop offset="0.61" stop-color="#F6DC7B" />
                <stop offset="0.72" stop-color="#FFF0A3" />
                <stop offset="0.84" stop-color="#F6DC7B" />
                <stop offset="1" stop-color="#B27E10" />
              </linearGradient>
              <linearGradient
                id="paint7_linear_1_102"
                x1="113.672"
                y1="134.436"
                x2="133.493"
                y2="82.0697"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#DDDDDD" stop-opacity="0" />
                <stop offset="1" stop-color="#0C0C0C" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_1_102"
                x1="168.243"
                y1="41.8965"
                x2="104.057"
                y2="126.234"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B27E10" />
                <stop offset="0.09" stop-color="#E2C05B" />
                <stop offset="0.14" stop-color="#F6DC7B" />
                <stop offset="0.26" stop-color="#FFF0A3" />
                <stop offset="0.36" stop-color="#F6DC7B" />
                <stop offset="0.49" stop-color="#B27E10" />
                <stop offset="0.57" stop-color="#E2C05B" />
                <stop offset="0.61" stop-color="#F6DC7B" />
                <stop offset="0.72" stop-color="#FFF0A3" />
                <stop offset="0.84" stop-color="#F6DC7B" />
                <stop offset="1" stop-color="#B27E10" />
              </linearGradient>
              <linearGradient
                id="paint9_linear_1_102"
                x1="143.644"
                y1="148.53"
                x2="137.553"
                y2="53.2309"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B27E10" />
                <stop offset="0.09" stop-color="#E2C05B" />
                <stop offset="0.14" stop-color="#F6DC7B" />
                <stop offset="0.26" stop-color="#FFF0A3" />
                <stop offset="0.36" stop-color="#F6DC7B" />
                <stop offset="0.49" stop-color="#B27E10" />
                <stop offset="0.57" stop-color="#E2C05B" />
                <stop offset="0.61" stop-color="#F6DC7B" />
                <stop offset="0.72" stop-color="#FFF0A3" />
                <stop offset="0.84" stop-color="#F6DC7B" />
                <stop offset="1" stop-color="#B27E10" />
              </linearGradient>
              <clipPath id="clip0_1_102">
                <rect width="216" height="215" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="pool-left" data-aos="fade-right">
          <div className="row">
            <div className="pool-left-title">
              <img src={logoText} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <div className="col-2-right">
                <h6 className="text-orange">DIGGING</h6>
                <p>
                  <span className="text-orange">0</span>
                  <span>FEET/HOUR</span>
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="col-2-right">
                <h6 className="text-orange">REWARDS</h6>
                <p>
                  <span className="text-orange">0</span>
                  <span>SOL</span>
                </p>
              </div>
            </div>
          </div>
          <div className="row-3">
            <div className="row">
              <h6>BALANCE</h6>
              <h6>DAILY PROFIT</h6>
            </div>
            <div className="row">
              <span>00000</span>
              <span className="text-bold">3%</span>
            </div>
          </div>
          <div className="row min-row">
            <p className="text-bold">
              <span className="text-orange">MIN</span> 0.1 SOL
            </p>
            <p className="text-bold">
              <span className="text-orange">MAX</span> 500 SOL
            </p>
          </div>
          <div className="pool-input">
            <input
              defaultValue={D}
              value={poolInputValue}
              type="number"
              onChange={(e) => {
                if (e?.target?.value > 50) {
                  setPoolInputValue(50);
                } else if (e?.target?.value < 0) {
                  setPoolInputValue(0.01);
                } else {
                  setPoolInputValue(e.target.value);
                }
              }}
            />
            <div className="pool-input-btn-list">
              <div
                className={`btn-minus ${isMinusActive ? "" : "not-active"}`}
                onClick={() => {
                  setIsMinusActive(true);
                  if (poolInputValue > 0.01) {
                    let num = poolInputValue - D;
                    setPoolInputValue(+num.toFixed(2));
                  }
                }}
              >
                <img src={MinusBtn} alt="minus-btn" />
              </div>
              <div
                className={`btn-plus ${isMinusActive ? "not-active" : ""}`}
                onClick={() => {
                  setIsMinusActive(false);
                  if (poolInputValue <= 49.99) {
                    let num = poolInputValue + D;
                    setPoolInputValue(+num.toFixed(2));
                  }
                }}
              >
                <img src={PlusBtn} alt="plus-btn" />
              </div>
            </div>
          </div>
          <p className="pool-small-text">ENTER SOL AMOUNT & CLICK HIRE BELOW</p>
          <div className="row row-btn">
            <button className="btn btn-medium btn-secondary">
              Compound Miners
            </button>
            <button className="btn btn-medium btn-secondary">
              Claim your SOL
            </button>
          </div>
          <div className="row">
            <button className="pool-hire bg-primary w-full center">
              HIRE SOL MINERS
            </button>
          </div>
        </div>
        <div className="pool-right" data-aos="fade-left">
          <h4 className="sub-title">HOW IT WORK</h4>
          <div className="pool-right-wrapper">
            <div className="pool-right-img">
              <img src={PoolRightImg} alt="pool-right-img" />
            </div>
            <div className="pool-right-content-list-wrapper ">
              <ul className="pool-right-content-list">
                <li className="pool-right-content-item">
                  {/* <div className="pool-circle">
                    <div className="pool-circle-inner"></div>
                  </div> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 50 51"
                    fill="none"
                  >
                    <circle cx="25" cy="25" r="25" fill="#D9D9D9" />
                    <g filter="url(#filter0_d_1_177)">
                      <circle cx="25" cy="25" r="17.3077" fill="#D9D9D9" />
                      <circle
                        cx="25"
                        cy="25"
                        r="17.3077"
                        fill="url(#paint0_linear_1_177)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_1_177"
                        x="3.69232"
                        y="7.69226"
                        width="42.6154"
                        height="42.6154"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1_177"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1_177"
                          result="shape"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_1_177"
                        x1="31.0944"
                        y1="-30.4712"
                        x2="-47.2434"
                        y2="-1.21535"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#00FFA3" />
                        <stop offset="1" stop-color="#DC1FFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p>Hire Miners using SOL tokens</p>
                </li>
                <li className="pool-right-content-item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 50 51"
                    fill="none"
                  >
                    <circle cx="25" cy="25" r="25" fill="#D9D9D9" />
                    <g filter="url(#filter0_d_1_177)">
                      <circle cx="25" cy="25" r="17.3077" fill="#D9D9D9" />
                      <circle
                        cx="25"
                        cy="25"
                        r="17.3077"
                        fill="url(#paint0_linear_1_177)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_1_177"
                        x="3.69232"
                        y="7.69226"
                        width="42.6154"
                        height="42.6154"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1_177"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1_177"
                          result="shape"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_1_177"
                        x1="31.0944"
                        y1="-30.4712"
                        x2="-47.2434"
                        y2="-1.21535"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#00FFA3" />
                        <stop offset="1" stop-color="#DC1FFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p>
                    Your miners will start digging, placing the tokens they find
                    into your barrel
                  </p>
                </li>
                <li className="pool-right-content-item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 50 51"
                    fill="none"
                  >
                    <circle cx="25" cy="25" r="25" fill="#D9D9D9" />
                    <g filter="url(#filter0_d_1_177)">
                      <circle cx="25" cy="25" r="17.3077" fill="#D9D9D9" />
                      <circle
                        cx="25"
                        cy="25"
                        r="17.3077"
                        fill="url(#paint0_linear_1_177)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_1_177"
                        x="3.69232"
                        y="7.69226"
                        width="42.6154"
                        height="42.6154"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1_177"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1_177"
                          result="shape"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_1_177"
                        x1="31.0944"
                        y1="-30.4712"
                        x2="-47.2434"
                        y2="-1.21535"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#00FFA3" />
                        <stop offset="1" stop-color="#DC1FFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p>
                    You can compound your earnings using the Compound button
                  </p>
                </li>
                <li className="pool-right-content-item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 50 51"
                    fill="none"
                  >
                    <circle cx="25" cy="25" r="25" fill="#D9D9D9" />
                    <g filter="url(#filter0_d_1_177)">
                      <circle cx="25" cy="25" r="17.3077" fill="#D9D9D9" />
                      <circle
                        cx="25"
                        cy="25"
                        r="17.3077"
                        fill="url(#paint0_linear_1_177)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_1_177"
                        x="3.69232"
                        y="7.69226"
                        width="42.6154"
                        height="42.6154"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1_177"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1_177"
                          result="shape"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_1_177"
                        x1="31.0944"
                        y1="-30.4712"
                        x2="-47.2434"
                        y2="-1.21535"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#00FFA3" />
                        <stop offset="1" stop-color="#DC1FFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p>
                    You can withdraw the tokens into your barrel using the Claim
                    your SOL button
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
