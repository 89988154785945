import React from "react";
import Logo from "../../assets/image/small_logo.png";

import "./style.scss";

export default function Footer() {
  return (
    <div className="footer-full">
      <div className="container">
        <footer className="footer">
          <div className="footer-left">
            <div className="footer-logo">
              <img src={Logo} alt="footer-logo" />
            </div>
            <p className="footer-desc">
              Explore the unique realm of SolMiner, an innovative DeFi venture
              thriving on Solana.
            </p>
          </div>
          <div className="footer-right">
            <ul className="footer-socials">
              <li>
                <a
                  href="https://twitter.com/SolMinerX"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <i className="fa-brands fa-discord"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/SolMinerTG"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <i className="fa-brands fa-telegram"></i>
                </a>
              </li>
            </ul>
          </div>
        </footer>
        <div className="footer-line"></div>
        <span className="copyright center">
          Copyright ©2022 SOLMINER All rights reserved
        </span>
      </div>
    </div>
  );
}
