import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";

// import BG from "../../assets/image/bg2.png";
import "./style.scss";

export default function Referral() {
  const [isCopied, setIsCopied] = useState(false);
  const [ref, setRef] = useState("");

  const { account } = useWeb3React();
  useEffect(() => {
    if (account) {
      setRef(window.origin + "?ref=" + account);
    } else {
      setRef("Login to see invite link");
    }
  }, [account]);
  return (
    <section className="referral" id="referral" data-aos="fade-up">
      <h4 className="sub-title text-orange">REFERRAL SYSTEM</h4>
      <p className="referral-desc center">
        By inviting nuew users to join and hire miners on BSC Miner Ecosystem,
        you can earn SOL direct to your wallet as soon as the invited person
        deposit their tokens.
      </p>
      <div className="referral-box">
        <div className="referral-box-link-wrapper">
          <div className="referral-box-link">
            <span>{ref}</span>
            <button
              className="btn btn-small btn-primary"
              onClick={() => {
                if (!account) return;
                setIsCopied(true);
                navigator.clipboard.writeText(ref);
              }}
            >
              {isCopied ? <i className="fa-solid fa-check"></i> : "Copy link"}
            </button>
          </div>
        </div>
        <p className="center">
          Promote your referral link to your friend and get 15% Rewards from
          referral system!
        </p>
      </div>
    </section>
  );
}
